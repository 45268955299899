import * as React from "react";
import { Link, Paragraph, useFormattingLanguage } from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { toFormattedNumberString } from "../../text/formatted-number/formatted-number";
import * as scss from "../dci-gauge/dci-gauge.scss";

export type BenchmarkTooltipProps = {
	/* Industry benchmark score */
	benchmark: number;
	/* The support article link to explain the industry score */
	benchmarkSupportLink: string;
	/* Optional industry name for tooltip to explain benchmark score */
	currentIndustryName?: string;
};

export function BenchmarkTooltip(props: BenchmarkTooltipProps): JSX.Element {
	const { benchmark, currentIndustryName, benchmarkSupportLink } = props;
	const locale = useFormattingLanguage();
	const i18n = useLabTranslations();
	return (
		<div className={scss.tooltipContent}>
			<Link href={benchmarkSupportLink} openNew>
				{i18n.industryBenchmark}
			</Link>
			<Paragraph>({currentIndustryName})</Paragraph>
			<div className={scss.value}>
				{toFormattedNumberString({
					format: "number",
					number: benchmark,
					alwaysShowDigits: true,
					digits: 1,
					locale,
				})}
			</div>
		</div>
	);
}

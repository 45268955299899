import * as React from "react";
import { Paragraph, useFormattingLanguage } from "@siteimprove/fancylib";
import { toFormattedNumberString } from "../../text/formatted-number/formatted-number";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "../dci-gauge/dci-gauge.scss";

export function TargetTooltip(props: { target: number }): JSX.Element {
	const { target } = props;
	const locale = useFormattingLanguage();
	const i18n = useLabTranslations();
	return (
		<div className={scss.tooltipContent}>
			<Paragraph>{i18n.siteTarget}</Paragraph>
			<div className={scss.value}>
				{toFormattedNumberString({
					format: "number",
					number: target,
					alwaysShowDigits: true,
					digits: 1,
					locale,
				})}
			</div>
		</div>
	);
}

import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { DCIGauge } from "./dci-gauge";

export const Meta: DocPageMeta = {
	category: "Data Visualization",
	title: "DCI Gauge",
	notepad: "https://hackmd.io/S8lzdkx3Sf2CAl7QsJY6Vg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Polar Gauge (DCI Score Gauge)"
			subTitle="Visually communicates progress toward a goal or target as a percentage within a circular format. The circle represents 100%."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={DCIGauge} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Primarily used for DCI scores, but can be adapted for other Siteimprove metrics with a
					target of 100. Ideal for tracking key performance indicators (KPIs) and completion rates.
					Ensure the goal is clear to the user.
				</Paragraph>
				<Paragraph>The component consists of:</Paragraph>
				<Ul
					items={[
						<>
							<b>Product Title</b>: Clearly indicates the metric being tracked (e.g., "DCI Score").
						</>,
						<>
							<b>Score</b>: Displays the current score in a x/100 format (e.g., "25.9/100").
						</>,
						<>
							<b>Progress Indicator</b>: Visually represents the score as a filled portion of the
							circle.
						</>,
						<>
							<b>Progress Change</b>: Shows the change in score over a specified period (e.g.,
							"+50.0") with a tooltip explaining the change (e.g., "Change to your score over the
							last 30 days").
						</>,
						<>
							<b>Custom Site Target Button</b>: (Icon-only) Allows the user to view the target
							score.
						</>,
						<>
							<b>Industry Benchmark Button</b>: (Icon-only) Provides a comparison to industry
							averages.
						</>,
					]}
				/>
				<Header.H2>Basic Usage</Header.H2>
				<Example fn={BasicUsage} />
				<Header.H2>Animation Changes Usage</Header.H2>
				<Paragraph>
					If you are unsatisfied with the default delay and duration of the animations, you can set
					them yourself to make the animation go faster or slower.
				</Paragraph>
				<Example fn={AnimationChangesUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={DCIGauge}
					initialProps={() => ({
						title: "Product Title",
						scoreValue: 25,
						benchmark: 75,
						delta: { value: 50, tooltipText: "Tooltip text", ariaLabel: "Change last 30 days:" },
						target: 90,
						currentIndustryName: "Industry Name",
						benchmarkSupportLink:
							"https://support.siteimprove.com/hc/en-gb/articles/115001853672-What-is-the-industry-benchmark",
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>DCIGauge</Code> when
					</Paragraph>
					<Ul
						items={[
							"A visual representation of progress is more impactful than a raw number.",
							"The metric being tracked should have a defined target of 100.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>DCIGauge</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Dashboard overview</b>: Provide a quick snapshot of progress.
							</>,
							<>
								<b>Detailed reports</b>: Supplement numerical data with a visual indicator.
							</>,
							<>
								<b>Widgets</b>: Offer a compact progress summary.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>DCIGauge</Code> to existing components for visual consistency.
							</>,
							<>
								<b>Purposeful Animation</b>:
								<Ul
									items={[
										<>
											<b>Meaningful Motion</b>: Animation should enhance, not distract from, the
											user experience. Each motion should have a clear purpose tied to improving
											comprehension or usability. For example, staggered animations can guide the
											user’s eye to data points in a specific order, highlighting key information.
										</>,
										<>
											<b>User-Centered Timing</b>: Animation speed should be deliberate and based on
											user needs, not arbitrary aesthetic choices. “Faster” or “slower” is
											subjective; instead, consider:
											<Ul
												items={[
													<>Does this speed help users grasp the information effectively?</>,
													<>Does it feel natural and intuitive within the flow of the interface?</>,
													<>
														Does it complement the gauge’s function and the overall user experience?
													</>,
												]}
											/>
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								<b>The data is highly volatile</b>: A gauge isn't suitable for rapidly changing
								values. Use{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Chart">Chart</GatsbyLink> or
								other data visualisation.
							</>,
							<>
								<b>Precise numerical values are critical</b>: A gauge is an approximation; use a
								numerical display if exact figures are necessary. Use{" "}
								<GatsbyLink to="/lab/components/Text/Highlight">Highlight</GatsbyLink> component
								instead.
							</>,
							<>
								<b>The metric does not have a target of 100</b>: A gauge requires a defined goal to
								measure against.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul items={["Ensure sufficient color contrast between the gauge's elements."]} />
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use clear, concise labels for the gauge title and any accompanying text.",
							<>Explain the meaning of the progress (e.g., "Progress towards DCI score of 95").</>,
							<>If applicable, offer guidance on how to improve the score or progress.</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/** @ignore [visual] */
const BasicUsage = () => {
	return (
		<DCIGauge
			title="Product Title"
			scoreValue={25}
			benchmark={75}
			benchmarkSupportLink={
				"https://support.siteimprove.com/hc/en-gb/articles/115001853672-What-is-the-industry-benchmark"
			}
			delta={{ value: 50, tooltipText: "Tooltip text", ariaLabel: "Change last 30 days:" }}
			target={90}
			currentIndustryName={"Industry Name"}
		/>
	);
};

/** @ignore */
const AnimationChangesUsage = () => {
	return (
		<DCIGauge
			title="Product Title"
			scoreValue={25}
			benchmark={75}
			benchmarkSupportLink={
				"https://support.siteimprove.com/hc/en-gb/articles/115001853672-What-is-the-industry-benchmark"
			}
			delta={{ value: 50, tooltipText: "Tooltip text", ariaLabel: "Change last 30 days:" }}
			target={90}
			currentIndustryName={"Industry Name"}
			animationDelay={0}
			animationDuration={600}
		/>
	);
};

import React from "react";
import {
	cn,
	Icon,
	IconGoalTarget,
	IconIndustryBenchmark,
	useFormattingLanguage,
} from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { CircularProgressProps, CircularProgress } from "../circular-progress/circular-progress";
import { toFormattedNumberString } from "../../text/formatted-number/formatted-number";
import { BenchmarkTooltip } from "../benchmark-tooltip/benchmark-tooltip";
import { TargetTooltip } from "../target-tooltip/target-tooltip";
import * as scss from "./dci-gauge.scss";
interface DCIGaugeProps extends Omit<CircularProgressProps, "benchmark" | "target"> {
	/** Value for the industry benchmark score */
	benchmark?: number;
	/** Value for the user set target score */
	target?: number;
	currentIndustryName?: string;
	benchmarkSupportLink?: string;
}

export function DCIGauge(props: DCIGaugeProps): JSX.Element {
	const { target, benchmark, benchmarkSupportLink, currentIndustryName } = props;
	const i18n = useLabTranslations();
	const benchmarkContent =
		benchmark && benchmarkSupportLink
			? {
					value: benchmark,
					tooltipContent: (
						<BenchmarkTooltip
							benchmark={benchmark}
							currentIndustryName={currentIndustryName}
							benchmarkSupportLink={benchmarkSupportLink}
						/>
					),
					tooltipAriaLabel: `${i18n.industryBenchmark}: ${benchmark}`,
			  }
			: undefined;

	const targetContent = target
		? {
				value: target,
				tooltipContent: <TargetTooltip target={target} />,
				tooltipAriaLabel: `${i18n.siteTarget}: ${target}`,
		  }
		: undefined;

	return (
		<div>
			<div className={scss.dciContainer}>
				<CircularProgress {...props} benchmark={benchmarkContent} target={targetContent} />
			</div>
			<DCIExportLegend benchmark={benchmark} target={target} />
		</div>
	);
}

export function DCIExportLegend(props: {
	benchmark?: number;
	target?: number;
	tooltipAriaLabelBenchmark?: string;
	tooltipAriaLabelTarget?: string;
}): JSX.Element {
	const { benchmark, target, tooltipAriaLabelBenchmark, tooltipAriaLabelTarget } = props;
	const locale = useFormattingLanguage();
	const format = (n: number) =>
		toFormattedNumberString({
			number: n,
			format: "number",
			alwaysShowDigits: true,
			digits: 1,
			locale: locale,
		});
	return (
		<div className={cn(scss.legend, "export-only")}>
			{!!benchmark && (
				<span>
					<Icon>
						<IconIndustryBenchmark />
					</Icon>
					{`${tooltipAriaLabelBenchmark}: ${format(benchmark)}`}
				</span>
			)}
			{!!target && (
				<span>
					<Icon>
						<IconGoalTarget />
					</Icon>
					{`${tooltipAriaLabelTarget}: ${format(target)}`}
				</span>
			)}
		</div>
	);
}

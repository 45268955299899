import {
	ColorHeatWarm1,
	ColorHeatWarm2,
	ColorHeatWarm3,
	ColorHeatWarm4,
	ColorHeatWarm5,
	ColorHeatWarm6,
	ColorHeatWarm7,
	ColorHeatWarm8,
	ColorHeatWarm9,
	ColorHeatWarm10,
	ColorHeatWarm11,
	ColorHeatWarm12,
} from "@siteimprove/fancylib/design-tokens/variables";

export function colorFromPercentageRedToGreenScale(percentage: number): string {
	if (percentage > 95) return ColorHeatWarm1;
	if (percentage > 90) return ColorHeatWarm2;
	if (percentage > 85) return ColorHeatWarm3;
	if (percentage > 80) return ColorHeatWarm4;
	if (percentage > 75) return ColorHeatWarm5;
	if (percentage > 70) return ColorHeatWarm6;
	if (percentage > 65) return ColorHeatWarm7;
	if (percentage > 60) return ColorHeatWarm8;
	if (percentage > 50) return ColorHeatWarm9;
	if (percentage > 40) return ColorHeatWarm10;
	if (percentage > 30) return ColorHeatWarm11;
	return ColorHeatWarm12;
}
